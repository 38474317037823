export function mapFormSchemaFields(schema, callback) {
	return {
		groups: schema.groups.map((group) => {
			return {
				...group,
				fields: group.fields.map((field) => callback(field)),
			};
		}),
	};
}

export function translateFormSchema(i18n, schema) {
	return mapFormSchemaFields(schema, (field) => {
		for (const [fieldKey, translate] of Object.entries(field.i18n)) {
			translateField(i18n, field, fieldKey, translate);
		}

		return field;
	});
}

export function updateSchemaFields(toUpdate, schema) {
	return mapFormSchemaFields(schema, (field) => {
		const fieldModel = field.model;

		if (!toUpdate[fieldModel]) {
			// nothing to update
			return field;
		}

		field = {
			...field,
			...toUpdate[fieldModel],
		};

		return field;
	});
}

function translateField(i18n, field, fieldKey, translate) {
	if (!translate) {
		return field;
	}

	if (typeof translate === 'string') {
		// translate is string key to translation
		field[fieldKey] = i18n.t(translate);
		return field;
	}

	if (typeof translate === 'object') {
		// translate is object
		for (const [nestedFieldKey, nestedTranslate] of Object.entries(translate)) {
			translateField(i18n, field[fieldKey], nestedFieldKey, nestedTranslate);
		}
		return field;
	}

	// translate is function, for example `get` property
	field[fieldKey] = translate(i18n);

	return field;
}
