<template>
	<b-card class="bcard-wrapper">
		<b-card-title v-if="showHeader">
			<slot name="header">
				{{ $t(headerTitle) }}
			</slot>

			<link-to-manual v-if="tooltipMsg" :tooltip="tooltipMsg"></link-to-manual>
		</b-card-title>

		<slot name="beforeForm">
			<!-- Place for content before Form Generator -->
		</slot>

		<slot name="formGenerator">
			<!-- Place for Form Generator -->
			<vue-form-generator
				class="detailForm"
				:schema="fSchema"
				:model="fModel"
				:options="fOptions"
				ref="detailForm"
			></vue-form-generator>
		</slot>

		<slot name="beforeFormActions">
			<!-- Place for content between Form and Form Actions -->
		</slot>

		<div class="form-generator-actions mt-3">
			<slot name="formActions">
				<!-- Place for Form Actions -->
				<megau-button
					v-if="showActionSave"
					classprop="btn btn-success"
					icon="check"
					:title="$t('savechanges2578')"
					@handleClick="$emit('saveForm', { closeOnSuccess: false })"
				></megau-button>

				<megau-button
					v-if="showActionSaveAndClose"
					classprop="btn btn-success"
					icon="check"
					:title="$t('savechanges')"
					@handleClick="$emit('saveForm', { closeOnSuccess: true })"
				></megau-button>

				<slot name="customActions"></slot>

				<megau-button
					v-if="showActionClose"
					classprop="btn btn-primary"
					icon="undo"
					:title="$t('cancel2168')"
					@handleClick="$emit('closeDetail')"
				></megau-button>

				<megau-button
					v-if="showActionDelete"
					classprop="btn btn-danger"
					icon="times"
					:title="$t('delete2169')"
					@handleClick="$emit('delete')"
				></megau-button>
			</slot>
		</div>

		<slot name="afterForm">
			<!-- Place for content after Form Generator -->
		</slot>
	</b-card>
</template>

<script>
import vueFormGenerator from 'vue-form-generator';
import deepmerge from 'deepmerge';
import loading from '@/mixins/loading.mixin';
import { defaultForm } from './detail-page/defaults';
import { translateFormSchema } from './detail-page/service';

export default {
	mixins: [loading],

	props: {
		showHeader: { type: Boolean, default: true },
		headerTitle: { type: String, defafult: '' },
		tooltipMsg: { type: String, default: null },
		formModel: { type: Object, default: () => ({}) },
		formSchema: { type: Object, default: () => ({}) },
		formOptions: { type: Object, default: () => ({}) },
		showActionSave: { type: Boolean, default: true },
		showActionSaveAndClose: { type: Boolean, default: true },
		showActionClose: { type: Boolean, default: true },
		showActionDelete: { type: Boolean, default: true },
	},

	data() {
		return {
			//
		};
	},

	created() {
		const res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
	},

	computed: {
		fModel() {
			return this.formModel;
		},

		fSchema() {
			// TODO clone formSchema
			if (!Object.keys(this.formSchema).length) {
				return {};
			}
			return translateFormSchema(this.$i18n, this.formSchema);
		},

		fOptions() {
			return deepmerge(defaultForm.options, this.formOptions);
		},
	},

	methods: {
		async validate(isAsync = null) {
			if (!this.$refs.detailForm) {
				throw 'Form was not set, nothing to validate';
			}

			const res = await this.$refs.detailForm.validate(isAsync);
			return Array.isArray(res) ? !res.length : res;
		},
	},
};
</script>
