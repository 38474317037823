var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "bcard-wrapper" },
    [
      _vm.showHeader
        ? _c(
            "b-card-title",
            [
              _vm._t("header", function () {
                return [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.$t(_vm.headerTitle)) + "\n\t\t"
                  ),
                ]
              }),
              _vm.tooltipMsg
                ? _c("link-to-manual", { attrs: { tooltip: _vm.tooltipMsg } })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._t("beforeForm"),
      _vm._t("formGenerator", function () {
        return [
          _c("vue-form-generator", {
            ref: "detailForm",
            staticClass: "detailForm",
            attrs: {
              schema: _vm.fSchema,
              model: _vm.fModel,
              options: _vm.fOptions,
            },
          }),
        ]
      }),
      _vm._t("beforeFormActions"),
      _c(
        "div",
        { staticClass: "form-generator-actions mt-3" },
        [
          _vm._t("formActions", function () {
            return [
              _vm.showActionSave
                ? _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("savechanges2578"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.$emit("saveForm", { closeOnSuccess: false })
                      },
                    },
                  })
                : _vm._e(),
              _vm.showActionSaveAndClose
                ? _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("savechanges"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.$emit("saveForm", { closeOnSuccess: true })
                      },
                    },
                  })
                : _vm._e(),
              _vm._t("customActions"),
              _vm.showActionClose
                ? _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-primary",
                      icon: "undo",
                      title: _vm.$t("cancel2168"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.$emit("closeDetail")
                      },
                    },
                  })
                : _vm._e(),
              _vm.showActionDelete
                ? _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-danger",
                      icon: "times",
                      title: _vm.$t("delete2169"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.$emit("delete")
                      },
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._t("afterForm"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }