import { cloneDeep } from 'lodash';
import loading from '@/mixins/loading.mixin';
import apiCommon from '@/services/api/common.api';
import { updateSchemaFields } from './detail-page/service';

export const detailPageMixin = {
	mixins: [loading],

	data() {
		return {
			controllerName: '',
			routeParam: 'id',
			detailPageRefName: 'detailForm',
			modelFromRoute: true,
			model: {},
			emptyModel: {},
			schema: {
				groups: [],
			},
		};
	},

	computed: {
		/**
		 * Route Id param
		 * Override to set valid route Id param
		 */
		detailEntityId() {
			return +this.$route.params[this.routeParam];
		},

		/**
		 * Route path to table
		 * Override to set valid route path to table
		 */
		tablePath() {
			return this.$route.path.substring(0, this.$route.path.lastIndexOf('/'));
		},
	},

	async beforeRouteUpdate(to, from, next) {
		if (!this.modelFromRoute) {
			return next();
		}

		const entityId = to.params[this.routeParam];
		if (entityId == null) {
			return next(this.tablePath);
		}

		if (entityId === 0) {
			this.updateFormModel(to.params.defaultModel);
			return next();
		}

		this.startLoading();
		this.loadModel(entityId);
		this.stopLoading();
		next();
	},

	async created() {
		this.model = cloneDeep(this.model);
		this.emptyModel = cloneDeep(this.model);

		this.startLoading();
		if (this.detailEntityId) {
			const promises = [this.loadResources()];
			if (this.modelFromRoute) {
				promises.push(this.loadModel());
			}
			await Promise.all(promises);
		} else {
			await this.loadResources();
		}
		this.stopLoading();
	},

	methods: {
		/**
		 * Transform model loaded from server to model used in component
		 * Override for custom transformation
		 */
		transformDtoToModel(dtoModel) {
			return dtoModel;
		},

		/**
		 * Transform model used in component to model for server
		 */
		transformModelToDto(model) {
			return model;
		},

		/**
		 * Load component resources
		 * Override and load component specific resources from server
		 */
		async loadResources() {},

		/**
		 * Update form schema
		 */
		updateSchemaFields(toUpdate) {
			this.schema = updateSchemaFields(toUpdate, this.schema);
		},

		/**
		 * Update or replace component model
		 */
		updateFormModel(newValues, replace = false) {
			if (replace) {
				this.model = newValues;
				return;
			}

			this.model = {
				...this.model,
				...newValues,
			};
		},

		/**
		 * Load data from server and update model
		 */
		async loadModel(id = null) {
			const value = id || this.detailEntityId;
			const resp = await apiCommon.getEntity(value, this.controllerName);
			const model = this.transformDtoToModel(resp.data);
			this.updateFormModel(model, true);
		},

		/**
		 * Insert new or update existing model
		 */
		async saveModel({ closeOnSuccess, reloadAfterSave = true }) {
			const isValid = await this.$refs[this.detailPageRefName].validate();
			if (!isValid) {
				return;
			}

			// start loading must be after the validation, otherwise the validation will not work
			this.startLoading();

			const method = this.detailEntityId ? 'putEntity' : 'postEntity';
			const dtoModel = this.transformModelToDto(this.model);
			const res = await apiCommon[method](dtoModel, this.controllerName)
				.then((res) => res.data)
				.finally(() => this.stopLoading());

			this.updateFormModel(this.emptyModel);

			if (closeOnSuccess) {
				return this.redirectToTable();
			}

			if (!reloadAfterSave) {
				return;
			}

			if (this.detailEntityId === 0) {
				return this.redirectToDetail(res);
			}

			return await this.loadModel();
		},

		/**
		 * Delete model
		 */
		async deleteModel(id = null) {
			await apiCommon.deleteEntity(id || this.detailEntityId, this.controllerName);
			this.redirectToTable();
		},

		/**
		 * Redirect to detail with given id
		 */
		redirectToDetail(id, defaultModel = {}) {
			this.$router.push({
				name: this.$route.name,
				params: { [this.routeParam]: id, defaultModel },
			});
		},

		/**
		 * Redirect back to table
		 */
		redirectToTable() {
			this.$router.push(this.tablePath);
		},
	},
};
